// import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

const state = {};

const getters = {
  // MD HISTORY
};

const actions = {
  // MD HISTORY
};
const mutations = {
  // MD HISTORY
};

export default {
  state,
  actions,
  mutations,
  getters
};
