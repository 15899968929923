import Vue from "vue";
import Router from "vue-router";

import LoadingComponent from "./LoadingComponent.vue";
import ErrorComponent from "./ErrorComponent.vue";

const lazyLoadView = ({ component, loading, error }) => {
  const AsyncHandler = () => ({
    component,
    loading,
    error
  });

  return () =>
    Promise.resolve({
      functional: true,
      render(h, { data, children }) {
        return h(AsyncHandler, data, children);
      }
    });
};

const MF = lazyLoadView({
  component: import("./view/pages/medical_file/file.vue"),
  loading: LoadingComponent,
  error: ErrorComponent
});

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      component: () => import("@/view/layout/Layout"),
      redirect: "/dashboard",
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/admin/users",
          name: "users",
          component: () => import("@/view/pages/admin/users/ManageUsers.vue")
        },
        {
          path: "/admin/payments",
          name: "payments",
          component: () => import("@/view/pages/admin/payments/AllPayments.vue")
        },
        {
          path: "/admin/manage",
          name: "actions",
          component: () => import("@/view/pages/admin/manage/ManageHome.vue")
        },
        {
          path: "/admin/notify",
          name: "notify",
          component: () => import("@/view/pages/admin/notify/NotifyHome.vue")
        },
        {
          path: "/admin/app-settings",
          name: "AppSettings",
          component: () => import("@/view/pages/admin/settings/Settings.vue")
        },
        {
          path: "/doctor/referral",
          name: "second_opinion",
          component: () => import("@/view/pages/doctor/referral.vue")
        },
        {
          path: "/doctor/schedule",
          name: "schedule",
          component: () => import("@/view/pages/doctor/schedule.vue")
        },
        {
          path: "/doctor/posts",
          name: "posts",
          component: () => import("@/view/pages/admin/manage/comp/feed.vue")
        },
        {
          path: "/medical_file/:id",
          name: "medical_file",
          component: MF
        },
        {
          path: "/appointments",
          name: "appointments",
          component: () => import("@/view/pages/appointment/appointment.vue")
        },
        {
          path: "/advice",
          name: "advice",
          component: () => import("@/view/pages/advice/advice.vue")
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/Profile.vue")
        },
        {
          path: "/chat",
          name: "chat",
          component: () => import("@/view/Chat/Room.vue")
        }
      ]
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/appointment/:id",
      name: "appointment",
      component: () => import("@/view/pages/app/appointement.vue")
    },
    {
      path: "/d/:id",
      name: "DoctorProfile",
      component: () => import("@/view/pages/doctor/Profile.vue")
    },
    {
      path: "/privacypolicy",
      name: "privacy_policy",
      component: () => import("@/view/pages/policy.vue")
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("@/view/pages/contact.vue")
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Register")
        },
        {
          name: "reset_password",
          path: "/reset_password",
          component: () => import("@/view/pages/auth/ForgotPassword")
        },
        {
          name: "registerdoc",
          path: "/doctor_register",
          component: () => import("@/view/pages/auth/Registerdoc")
        }
        // {
        //   name: 'confirmemail',
        //   path: '/confirmemail',
        //   component: () => import('@/view/pages/auth/ConfirmEmail'),
        // },
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ],
  mode: "history"
});
