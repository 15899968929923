import ApiService from "@/core/services/api.service";

/**
 * User Management Service
 * - get users
 * - add users
 * - edit users
 * - delete users
 * - store current user
 */
const QuestionService = {
  init() {},
  data: {
    page: 1,
    total: 0,
    limit: 50,
    fields: [
      "id",
      "status",
      "department.id",
      "department.name",
      "department.avatar.private_hash",
      "created_on",
      "modified_on",
      "doctor.doctor_id.first_name",
      "doctor.doctor_id.last_name",
      "doctor.doctor_id.avatar.private_hash",
      "doctor.doctor_id.id",
      "doctor.doctor_id.external_id",
      "owner.id",
      "owner.first_name",
      "owner.last_name",
      "owner.avatar.private_hash",
      "owner.id,rating",
      "question",
      "answer",
      "title"
    ],
    options: {
      limit: 50,
      meta: true,
      page: 1,
      sort: "-created_on",
      filter: {}
    }
  },

  getOptions() {
    return this.data.options;
  },
  setOptions(o) {
    this.data.options = o;
  },
  resetOptions() {
    this.data.options = {
      limit: 50,
      meta: true,
      page: 1,
      sort: "-created_on",
      filter: {}
    };
    return this.data.options;
  },
  getPage() {
    return this.data.page;
  },
  setPage(p) {
    this.data.page = p;
  },
  getLimit() {
    return this.data.limit;
  },
  getTotal() {
    return this.data.total;
  },
  async deleteItem(id) {
    return ApiService.delete(`items/question/${id}`);
  },
  async postAnswer(id, payload) {
    return ApiService.update("items/question", id, payload);
  },
  async postQuestion(payload) {
    return ApiService.post("items/question", payload);
  },

  async getItems(options = []) {
    let filters = ApiService.processOptions(options);
    // console.log("query sent to server is", `question?fields=${this.data.fields.join()}` + filters)
    let result = await ApiService.get(
      "items",
      `question?fields=${this.data.fields.join()}` + filters
    );
    this.data.total = result.data.meta.total_count;
    return result;
  }
};
export default QuestionService;
