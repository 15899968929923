import ApiService from "@/core/services/api.service";
import { Base64 } from "js-base64";
import DbService from "../db.service";
/**
 * Service to call HTTP request via Axios
 */
const VitalService = {
  data: {
    vitalTypes: null,
    gun: null
  },
  init() {
    this.getPouch();
  },
  async getPouch() {
    let res = await DbService.getlocaldb().get("vital_types");
    this.data.vitalTypes = res;
  },
  updatePouch() {
    DbService.getlocaldb()
      .put(this.data.vitalTypes)
      .then(data => {
        this.data.vitalTypes["_rev"] = data.rev;
      });
  },
  setPouch() {
    // console.log("setting vitals pouch")
    DbService.getlocaldb().put({
      _id: "vital_types",
      data: Base64.encode(JSON.stringify(this.data.vitalTypes))
    });
  },
  setGun(g) {
    this.data.gun = g;
    this.data.gun.get("mf_defaults/vital_types").once(e => {
      if (e) this.data.vitalTypes = JSON.parse(Base64.decode(e.data));
    });
  },
  updategun() {
    this.data.gun.get("mf_defaults/vital_types").once(e => {
      let d = Base64.encode(JSON.stringify(this.data.vitalTypes));
      if (!e || d != e.data) {
        this.data.gun.get("mf_defaults/vital_types").put({ data: d });
      }
    });
  },

  async create(payload) {
    let res = ApiService.post("items/vital_types", payload);
    return res;
  },
  async update(id, payload) {
    let res = ApiService.update("items/vital_types", id, payload);
    return res;
  },
  async deleteItem(id) {
    let res = ApiService.delete(`items/vital_types/${id}`);
    return res;
  },
  async deleteTranslation(payload) {
    let ids = "";
    payload.forEach((item, index) => {
      ids += item.id;
      if (index != payload.length - 1) ids += ",";
    });
    if (ids != "") {
      let res = ApiService.delete(`items/vital_type_translation/${ids}`);
      // console.log(res);
      return res;
    }
    return false;
  },
  /**
   * Check if the vital type is being used
   * @param id id of vital type
   * @returns {boolean}
   */
  async isInUse(id) {
    let res = await ApiService.get(
      `items/md_vitals?fields=id&filter[vital_type]=${id}&limit=1`
    );
    if (res.data.data.length == 0) return false;
    else return true;
  },
  /**
   * GET All Vital Types
   * @param force if true, force loading from server, if false, return local items if available, if not, load from server
   * @returns {vitalTypes}
   */
  async getItem(force = false) {
    let fetchfromserver = this.data.vitalTypes == null || force;
    // console.log("data",this.data, "is data null", this.data.vitalTypes == null, "fetch from server",fetchfromserver);
    if (fetchfromserver) {
      // console.log("loading from remote")
      let result = await ApiService.get(
        "items",
        "vital_types?fields=*,translation.*,icon.*"
      );
      this.data.vitalTypes.data = Base64.encode(
        JSON.stringify(result.data.data)
      );
      this.updatePouch();
      return result.data.data;
    } else {
      // console.log("returning from local", this.data.vitalTypes)
      return JSON.parse(Base64.decode(this.data.vitalTypes.data));
    }
  }
};
export default VitalService;
