import ApiService from "@/core/services/api.service";
import { Base64 } from "js-base64";
import DbService from "../db.service";

/**
 * Service to call HTTP request via Axios
 */
const HistoryService = {
  data: {
    historyTypes: null
  },
  init() {
    this.getPouch();
  },
  async getPouch() {
    let res = await DbService.getlocaldb().get("history_type");
    this.data.historyTypes = res;
  },
  updatePouch() {
    DbService.getlocaldb()
      .put(this.data.historyTypes)
      .then(data => {
        this.data.historyTypes["_rev"] = data.rev;
      });
  },
  setPouch() {
    // console.log("setting history pouch")
    DbService.getlocaldb().put({
      _id: "history_type",
      data: Base64.encode(JSON.stringify(this.data.historyTypes))
    });
  },

  async create(payload) {
    let res = ApiService.post("items/history_type", payload);
    return res;
  },
  async update(id, payload) {
    let res = ApiService.update("items/history_type", id, payload);
    return res;
  },
  async deleteItem(id) {
    let res = ApiService.delete(`items/history_type/${id}`);
    return res;
  },
  async deleteTranslation(payload) {
    let ids = "";
    payload.forEach((item, index) => {
      ids += item.id;
      if (index != payload.length - 1) ids += ",";
    });
    if (ids != "") {
      let res = ApiService.delete(`items/history_type_translation/${ids}`);
      // console.log(res);
      return res;
    }
    return false;
  },
  /**
   * Check if the history type is being used
   * @param id id of history type
   * @returns {boolean}
   */
  async isInUse(id) {
    let res = await ApiService.get(
      `items/md_history?fields=id&filter[history_type]=${id}&limit=1`
    );
    if (res.data.data.length == 0) return false;
    else return true;
  },
  /**
   * GET All history Types
   * @param force if true, force loading from server, if false, return local items if available, if not, load from server
   * @returns {historyTypes}
   */
  getlocalItems() {
    return JSON.parse(Base64.decode(this.data.historyTypes.data));
  },
  async getItem(force = false) {
    let fetchfromserver = this.data.historyTypes == null || force;
    // console.log("data",this.data, "is data null", this.data.historyTypes == null, "fetch from server",fetchfromserver);
    if (fetchfromserver) {
      // console.log("loading from remote")
      let result = await ApiService.get(
        "items",
        "history_type?fields=*,translation.*,icon.*"
      );
      this.data.historyTypes.data = Base64.encode(
        JSON.stringify(result.data.data)
      );
      this.updatePouch();
      return result.data.data;
    } else {
      // console.log("returning from local", this.data.historyTypes)
      return JSON.parse(Base64.decode(this.data.historyTypes.data));
    }
  }
};
export default HistoryService;
