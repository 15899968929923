import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
//import UserService from "../user.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  // async login({ commit }, credentials) {
  //   try {
  //     const { data } = await ApiService.post('auth/authenticate', credentials);
  //     return commit(SET_AUTH, data.data);
  //   } catch (error) {
  //     // console.log('error', error);
  //     return;
  //   }
  // },

  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("auth/authenticate", credentials)
        .then(({ data }) => {
          //// console.log("userdata: ", data.data.user);
          context.commit(SET_AUTH, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.error.message);
        });
    });
  },
  signout({ commit }) {
    commit(PURGE_AUTH);
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },

  async createUser({ commit }, credentials) {
    try {
      // register user
      await ApiService.registerUser("users", credentials);
    } catch (error) {
      // console.log(error);
      return commit(SET_ERROR, error);
    }
  },

  async signup({ commit }, credentials) {
    try {
      //// console.log("regestering user", credentials);
      // register user
      if (credentials.role == "patient") {
        const { data } = await ApiService.registerUser(
          "custom/register/register_patient",
          credentials
        );
        //// console.log("res", data.data);
        return data.data;
      }

      // // console.log('reg data after recaptch', JSON.parse(user.config.data));

      // login user
      // const { data } = await ApiService.post('auth/authenticate', {
      //   email: credentials.email,
      //   password: credentials.password,
      // });

      // commit(SET_AUTH, data.data);
    } catch (error) {
      //// console.log(error);
      return commit(SET_ERROR, error);
    }
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("users", { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.error.message);
          reject(response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      /*ApiService.setHeader();
      ApiService.get("users/me?fields=id")
        .catch(( error ) => {
          if(error.response.status == 401)
          {
            // console.log("auth error",error.response);
            context.commit(PURGE_AUTH);
            window.location.reload();
          }
          
        });*/
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    return (state.errors = error);
  },
  [SET_AUTH](state, user) {
    // // console.log("setting user in set auth", user)
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(user.token);
    localStorage.userInfo = JSON.stringify(user.user);
  },
  [PURGE_AUTH](state) {
    //// console.log("i was called");
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    ApiService.removeHeader();
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
