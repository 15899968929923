import PouchDB from "pouchdb";
import ApiService from "@/core/services/api.service";
import UserService from "./user.service";
import UtilityService from "@/core/services/utility.service";

const FeedService = {
  init() {
    this.db = new PouchDB(
      `https://${process.env.VUE_APP_COUCH_CRD}@${process.env.VUE_APP_COUCH_URL}/feed`
    );
  },
  db: null,
  data: {
    page: 1,
    total: 0,
    limit: 50,
    fields: [
      "id",
      "status",
      "cover.private_hash",
      "created_on",
      "owner.id",
      "owner.first_name",
      "owner.last_name",
      "owner.avatar.private_hash",
      "type",
      "translation.*",
      "title"
    ],
    options: {
      limit: 50,
      meta: true,
      page: 1,
      sort: "-created_on",
      filter: {}
    },
    gun: null
  },
  getOptions() {
    return this.data.options;
  },
  setOptions(o) {
    this.data.options = o;
  },
  resetOptions() {
    this.data.options = {
      limit: 50,
      meta: true,
      page: 1,
      sort: "-created_on",
      filter: {}
    };
    return this.data.options;
  },

  getPage() {
    return this.data.page;
  },
  setPage(p) {
    this.data.page = p;
  },
  getLimit() {
    return this.data.limit;
  },
  getTotal() {
    return this.data.total;
  },

  likeAction(item) {
    // console.log("item to handle", item)
    let list = UtilityService.getDS().record.getList(
      `social/feed/${item.id}/likes`
    );

    // console.log("ds feed data", list)

    if (item.like) {
      list.removeEntry(`/like/${UserService.getCurrentUser().external_id}`);
      list.whenReady(() => {
        UtilityService.getDS()
          .record.getRecord(`/like/${UserService.getCurrentUser().external_id}`)
          .delete();
      });
    } else {
      UtilityService.getDS()
        .record.getRecord(`like/${UserService.getCurrentUser().external_id}`)
        .set({
          date: Date.now(),
          device: UtilityService.getDeviceInfo().operatingSystem
        });
      list.addEntry(`/like/${UserService.getCurrentUser().external_id}`);
    }
  },
  async getItems(options = []) {
    let filters = ApiService.processOptions(options);
    // console.log("query sent to server is", `question?fields=${this.data.fields.join()}` + filters)
    let result = await ApiService.get(
      "items",
      `feed?fields=${this.data.fields.join()}` + filters
    );
    this.data.total = result.data.meta.total_count;
    if (result.status === 200) {
      result.data.data.forEach(item => {
        let list = UtilityService.getDS().record.getList(
          `social/feed/${item.id}/likes`
        );
        list.whenReady(list => {
          item.likes = list.getEntries().length;
          item.like = list
            .getEntries()
            .includes("/like/" + UserService.getCurrentUser().external_id);
          UtilityService.EventBus.$emit("updatefeedpage", item.id);
        });
      });
    }
    return result;
  },
  async getFeedItems(owner = null, page = this.data.page) {
    // console.log("page",page, "owner",owner)
    let result = await ApiService.get(
      "items",
      `feed?fields=*,owner.first_name,owner.last_name,owner.avatar.*,owner.external_id,translation.*,cover.private_hash&sort=-created_on&limit=${this.data.limit}&page=${page}&meta=*` +
        (owner !== null ? `&filter[owner]=${owner}` : ``)
    );
    this.data.total = result.data.meta.total_count;
    // console.log("feed data", result.data.data)
    if (result.status === 200) {
      result.data.data.forEach(item => {
        let list = UtilityService.getDS().record.getList(
          `social/feed/${item.id}/likes`
        );
        list.whenReady(list => {
          item.likes = list.getEntries().length;
          item.likesdata = list.getEntries();
          item.like = list
            .getEntries()
            .includes("/like/" + UserService.getCurrentUser().external_id);
          UtilityService.EventBus.$emit("updatefeedpage");
        });
      });
    }
    return result.status == 200 ? result.data.data : [];
  },
  async postItem(payload) {
    return ApiService.post("items/feed", payload);
  },
  async updateItem(id, payload) {
    return ApiService.update("items/feed", id, payload);
  },
  async postTranslation(payload) {
    return ApiService.post("items/feed_translation", payload);
  },
  async updateTranslationItem(id, payload) {
    return ApiService.update("items/feed_translation", id, payload);
  },
  async deleteTranslations(payload) {
    let ids = "";
    payload.forEach((item, index) => {
      ids += item.id;
      if (index != payload.length - 1) ids += ",";
    });
    if (ids != "") {
      let res = ApiService.delete(`items/feed_translation/${ids}`);
      return res;
    }
    return false;
  },
  async deleteItem(item) {
    await this.deleteTranslations(item.translation);
    try {
      let list = UtilityService.getDS().record.getList(
        `social/feed/${item.id}/likes`
      );
      list.whenReady(list => {
        list.getEntries().forEach(l => {
          list.removeEntry(l);
          UtilityService.getDS()
            .record.getRecord(l)
            .delete();
        });
      });
      list.delete();
    } catch (error) {
      return;
    }

    return ApiService.delete(`items/feed/${item.id}`);
  }
};
export default FeedService;
