// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import UtilityService from "@/core/services/utility.service";
import NotificationService from "./notification.service";
import UserService from "./user.service";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// Initialize Firebase

/**
 * User Management Service
 * - get users
 * - add users
 * - edit users
 * - delete users
 * - store current user
 */
const FirebaseService = {
  init() {
    this.data.app = initializeApp(this.data.config);
    try {
      this.data.messaging = getMessaging();
      onMessage(this.data.messaging, payload => {
        // console.log('Message received. ', payload);
        // ...
        UtilityService.EventBus.$emit("showalert", {
          msg: payload.notification.body
        });
      });
    } catch (error) {
      // console.log("error setting up firebase messeging",error)
    }
  },

  data: {
    config: {
      apiKey: process.env.VUE_APP_firebase_API_KEY,
      authDomain: "doctorycare.firebaseapp.com",
      databaseURL: "https://doctorycare.firebaseio.com",
      projectId: "doctorycare",
      storageBucket: "doctorycare.appspot.com",
      messagingSenderId: process.env.VUE_APP_firebase_MSG_SENDER_ID,
      appId: process.env.VUE_APP_firebase_APP_ID
    },
    app: null,
    messaging: null
  },
  async getWebToken() {
    try {
      getToken(this.data.messaging, { vapidKey: process.env.VUE_APP_VIPD_KEY })
        .then(currentToken => {
          if (currentToken) {
            // // console.log("current token is", currentToken)
            NotificationService.syncToken(
              currentToken,
              UtilityService.getDeviceInfo(),
              UserService.getCurrentUser()
            );
          } else {
            // Show permission request UI
            // console.log('No registration token available. Request permission to generate one.');
            // ...
          }
        })
        .catch(err => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
    } catch (error) {
      // console.log("error setting up firebase notifications",error)
    }

    //return this.data.messaging.getToken({ vapidKey: process.env.VUE_APP_VIPD_KEY })
  }
};
export default FirebaseService;
