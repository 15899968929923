import Vue from "vue";
import dApp from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import NotificationService from "@/core/services/notification.service";
import LocalStorageService from "@/core/services/localstorage.service";
import DepartmentService from "@/core/services/department.service";
import VitalService from "@/core/services/mf/vital.service";
import HistoryService from "@/core/services/mf/history.service";
import PaymentService from "@/core/services/payment.service";
import UserService from "@/core/services/user.service";
import FileService from "@/core/services/mf/file.service";
import DoctorService from "@/core/services/doctor.service";
import PrescriptionService from "@/core/services/mf/prescription.service";
import AppointmentService from "@/core/services/appointment.servic";
import ChatService from "@/core/services/chat.service";
import FeedService from "./core/services/feed.service";
import QuestionService from "./core/services/qustion.service";
import DbService from "./core/services/db.service";
import StatsService from "./core/services/stats.service";
import FirebaseService from "./core/services/firebase.service";
import FeedbackService from "./core/services/feedback.service";
import i18n from "@/core/plugins/vue-i18n";

import UtilityService from "@/core/services/utility.service";

import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { DeepstreamClient } from "@deepstream/client";

import Textra from "vue-textra";

import { Plugins } from "@capacitor/core";
const { App } = Plugins;
const { Device } = Plugins;
let lang = "en";
Device.getLanguageCode().then(data => {
  // console.log("lang",data)
  lang = data.value.split("-")[0];
  // console.log("lang",lang)
  if (!window.localStorage["language"]) {
    // console.log("did set language")
    window.localStorage.setItem("language", lang);
    i18n.locale = lang;
    //window.location.reload(true);
  }
});

import LogRocket from "logrocket";

if (process.env.NODE_ENV === "production") {
  LogRocket.init("zkwadp/doctorycare", {
    release: process.env.version,
    baseHref: "https://doctory.care/"
  });
  Vue.prototype.$rocket = LogRocket;
}

Vue.config.productionTip = false;

import VueMoment from "vue-moment";
import moment from "moment-timezone";
import "boxicons";
import "boxicons/css/boxicons.min.css";

import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);

import Unicon from "vue-unicons/dist/vue-unicons-vue2.umd";
import {
  uniEstate,
  uniHeartbeat,
  uniCalender,
  uniCommentAltQuestion,
  uniQuestionCircle,
  uniListUl,
  uniTrashAlt,
  uniEditAlt,
  uniCloudDownload,
  uniVideo,
  uniFileMedicalAlt,
  uniFileCheckAlt,
  uniDollarAlt,
  uniUserArrows,
  uniHospital,
  uniUsersAlt,
  uniCommentRedo,
  uniClock,
  uniArrowGrowth,
  uniSetting,
  uniUserMd,
  uniUser,
  uniKeySkeleton,
  uniSelfie,
  uniBag,
  uniUserPlus,
  uniHeartRate,
  uniTransaction,
  uniBandAid,
  uniUploadAlt,
  uniHistory,
  uniCapsule,
  uniFileCopyAlt,
  uniApple,
  uniAndroid,
  uniLaptopCloud,
  uniPoundCircle,
  uniCheckCircle,
  uniExclamationCircle,
  uniLaptop,
  uniMobileAndroid,
  uniCreditCard,
  uniUserCircle,
  uniBell,
  uniSignAlt,
  uniPauseCircle,
  uniSignOutAlt,
  uniCommentsAlt,
  uniDatabase,
  uniSave,
  uniEnglishToChinese,
  uniWifi,
  uniSpinnerAlt,
  uniExclamationTriangle,
  uniStethoscope,
  uniFavorite,
  uniDocumentLayoutLeft
} from "vue-unicons/dist/icons";

Unicon.add([
  uniEstate,
  uniHeartbeat,
  uniCalender,
  uniCommentAltQuestion,
  uniQuestionCircle,
  uniListUl,
  uniTrashAlt,
  uniEditAlt,
  uniCloudDownload,
  uniVideo,
  uniFileMedicalAlt,
  uniFileCheckAlt,
  uniDollarAlt,
  uniUserArrows,
  uniHospital,
  uniUsersAlt,
  uniCommentRedo,
  uniClock,
  uniArrowGrowth,
  uniSetting,
  uniUserMd,
  uniUser,
  uniKeySkeleton,
  uniSelfie,
  uniBag,
  uniUserPlus,
  uniHeartRate,
  uniTransaction,
  uniBandAid,
  uniUploadAlt,
  uniHistory,
  uniCapsule,
  uniFileCopyAlt,
  uniApple,
  uniAndroid,
  uniLaptopCloud,
  uniPoundCircle,
  uniCheckCircle,
  uniExclamationCircle,
  uniLaptop,
  uniMobileAndroid,
  uniCreditCard,
  uniUserCircle,
  uniBell,
  uniSignAlt,
  uniPauseCircle,
  uniSignOutAlt,
  uniCommentsAlt,
  uniDatabase,
  uniSave,
  uniEnglishToChinese,
  uniWifi,
  uniSpinnerAlt,
  uniExclamationTriangle,
  uniStethoscope,
  uniFavorite,
  uniDocumentLayoutLeft
]);
Vue.use(Unicon);

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
//import PerfectScrollbar from 'perfect-scrollbar';
//window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

import PerfectScrollbar2 from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

Vue.use(PerfectScrollbar2);

// Vue 3rd party plugins

import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@mdi/font/css/materialdesignicons.css";
if (!window.location.hostname.includes("localhost"))
  require("./registerServiceWorker");
//import VueGlobalVar from "vue-global-var";

//GUN
import Gun from "gun/gun";
import "gun/lib/unset.js";

let gunInstance = Gun([process.env.VUE_APP_gun_base], {
  localStorage: false
});
Vue.prototype.$gun = gunInstance;
const ds = new DeepstreamClient(process.env.VUE_APP_ds);
Vue.prototype.$deepstream = ds;

DbService.init();
Vue.prototype.$db = DbService;

// API service init
ApiService.init();
FirebaseService.init();
UtilityService.setGun(gunInstance);
UtilityService.init(ds, UserService);
ChatService.init(gunInstance);

Vue.prototype.$api = ApiService;
Vue.prototype.$notify = NotificationService;
Vue.prototype.$ls = LocalStorageService;
Vue.prototype.$department = DepartmentService;
Vue.prototype.$vitalTypes = VitalService;
Vue.prototype.$historyTypes = HistoryService;
Vue.prototype.$payments = PaymentService;
Vue.prototype.$user = UserService;
Vue.prototype.$file = FileService;
Vue.prototype.$doctor = DoctorService;
Vue.prototype.$util = UtilityService;
Vue.prototype.$prescription = PrescriptionService;
Vue.prototype.$app = AppointmentService;
Vue.prototype.$cap = App;
Vue.prototype.$chat = ChatService;
Vue.prototype.$feed = FeedService;
Vue.prototype.$question = QuestionService;
Vue.prototype.$stats = StatsService;
Vue.prototype.$firebase = FirebaseService;
Vue.prototype.$feedback = FeedbackService;

import VueCryptojs from "vue-cryptojs";

Vue.use(VueCryptojs);

//console.log("api url is",process.env.VUE_APP_api_url)

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.

  Promise.all([store.dispatch(VERIFY_AUTH)]).then(() => {
    //console.log("isauth", !store.getters.isAuthenticated, "to name",to.name , "condition is,",to.name !== 'login');
    if (to.name === "register" && !store.getters.isAuthenticated) next();
    else if (to.name === "reset_password" && !store.getters.isAuthenticated)
      next();
    else if (to.name !== "login" && !store.getters.isAuthenticated)
      next({ name: "login" });
    else next();
  });

  // reset config to initial state

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
//Vue.use(require('vue-moment'));
Vue.use(VueMoment, {
  moment
});

Vue.use(Textra);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  data: {
    //only place where data is not a function
    title: "Doctory",
    loader: true,
    ssdkurl_prod: "https://web.squarecdn.com/v1/square.js",
    ssdkurl_test: "https://sandbox.web.squarecdn.com/v1/square.js",
    stoken_prod: "sq0idp-T2T1TyoCyGmcGoT-SNKrFQ",
    stoken_test: "sandbox-sq0idb-NbciSFMGENFLLObmp88nvA",
    sloc_prod: "LG9CCNP10EJP9",
    sloc_test: "L21P4F2PM39CQ",
    lang: !window.localStorage["language"]
      ? lang
      : window.localStorage["language"],
    mobile: false,
    mf: 0,
    doctormf: 0,
    viaid: 0,
    docID: 0,
    exchangerate: 1,
    connectionstatus: true,
    disablehome: true,
    chatOpen: false,
    showBar: false,
    apiURL: process.env.VUE_APP_api_url,
    assetsBaseURL: process.env.VUE_APP_assets_url,
    asseturl: process.env.VUE_APP_api_url + "assets/",
    sysbarheight: 0,
    tabspadding: 0,
    currentpage: "dashboard",
    didload: false,
    patient: {
      selectedDepartment: null
    },
    admin: {
      extramargin: "56px",
      selectedRole: 1,
      openside: false,
      currentuser: null,
      selectedSetting: "system",
      selectedManageData: "apps"
    }
  },
  render: h => h(dApp)
}).$mount("#app");
