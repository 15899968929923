import ApiService from "@/core/services/api.service";
// import UtiliityService from "./utility.service";
//import ls from "@/core/services/localstorage.service";
/**
 * Service to call HTTP request via Axios
 */
const StatsService = {
  data: {
    appointments: {
      total: 0,
      inprogress: 0,
      pending: 0,
      canceled: 0,
      completed: 0
    },
    questions: { total: 0, answered: 0, unanswred: 0, depart: [] },
    consultations: { total: 0 },
    gun: null
  },
  init() {},

  async getAllAppointmentsStats() {
    let result = await ApiService.get(
      "items",
      "appointments?fields=id,status,type,date_time,created_on,doctor.department.name,doctor.department.id,doctor.department.avatar.private_hash" +
        `&limit=-1&sort=-date_time&meta=*`
    );
    if (result.status === 200) {
      return result.data;
    } else return null;
  },
  async getAllUsersStats() {
    const result = await ApiService.get(
      "/users" + "?fields=id,role.name,role.id,status,timezone&limit=-1"
    );

    if (result.status === 200) {
      return result.data;
    } else return null;
  }
};
export default StatsService;
