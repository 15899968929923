import ApiService from "@/core/services/api.service";
import UtilityService from "@/core/services/utility.service";

/**
 * User Management Service
 * - get users
 * - add users
 * - edit users
 * - delete users
 * - store current user
 */
const AppointmentService = {
  init() {},
  data: {
    page: 1,
    total: 0,
    limit: 50,
    fields: [
      "id",
      "status",
      "doctor.department.id",
      "doctor.department.name",
      "doctor.department.avatar.private_hash",
      "created_on",
      "modified_on",
      "doctor.id",
      "doctor.doctor_id.first_name",
      "doctor.doctor_id.last_name",
      "doctor.doctor_id.avatar.private_hash",
      "doctor.doctor_id.id",
      "video_room_id",
      "rating",
      "type",
      "date_time",
      "medical_file",
      "rating",
      "medical_file.owner.id",
      "medical_file.owner.first_name",
      "medical_file.owner.last_name",
      "medical_file.owner.avatar.private_hash",
      "medical_file.id",
      "medical_file.birthday",
      "medical_file.gender"
    ],
    options: {
      limit: 50,
      meta: true,
      page: 1,
      sort: "-created_on",
      filter: {}
    }
  },

  getOptions() {
    return this.data.options;
  },
  setOptions(o) {
    this.data.options = o;
  },
  resetOptions() {
    this.data.options = {
      limit: 50,
      meta: true,
      page: 1,
      sort: "-created_on",
      filter: {}
    };
    return this.data.options;
  },
  getPage() {
    return this.data.page;
  },
  setPage(p) {
    this.data.page = p;
  },
  getLimit() {
    return this.data.limit;
  },
  getTotal() {
    return this.data.total;
  },

  async createItem(payload) {
    return ApiService.post("items/appointments", payload);
  },

  async getItems(options = []) {
    let filters = ApiService.processOptions(options);
    // console.log("query sent to server is", `question?fields=${this.data.fields.join()}` + filters)
    let result = await ApiService.get(
      "items",
      `appointments?fields=${this.data.fields.join()}` + filters
    );
    this.data.total = result.data.meta.total_count;
    return result;
  },
  /**
   *
   * @param {*} id
   * @param {*} key
   * @param {*} value
   * @param {*} resource
   * @returns
   */
  async getItemByPaymentID(id) {
    return ApiService.get(
      "items",
      `appointments?fields=*,doctor.doctor_id.first_name,doctor.doctor_id.last_name,doctor.doctor_id.avatar.private_hash&filter[payment]=${id}`
    );
  },

  async getDoctorsAppointmentByDate(id, date) {
    return ApiService.get(
      "items",
      `appointments?fields=id,date_time&filter[doctor]=${id}&filter[date_time][contains]=${date}`
    );
  },

  async getDoctorAvailability(doctor, date) {
    // console.log("doctor", doctor, "date", date)
    let duration = 0;
    doctor.call_duration
      ? (duration = doctor.call_duration)
      : (duration = UtilityService.getSettings().call_duration);

    let dayofweek = UtilityService.day(`${date}`).format("ddd");
    let availObj = doctor.availability.find(a => {
      return a.days.includes(dayofweek.toLowerCase());
    });
    // console.log("availablity",doctor.availability);
    // console.log("availObj", availObj)
    if (availObj === undefined) return [];

    let starttime = UtilityService.day(`${date}T${availObj.from}Z`);
    let endtime = UtilityService.day(`${date}T${availObj.to}Z`).subtract(
      duration,
      "m"
    );

    if (UtilityService.day().isAfter(endtime)) return [];
    let res = await this.getDoctorsAppointmentByDate(doctor.id, date);
    let times = [];

    while (starttime.isBefore(endtime) || starttime.isSame(endtime)) {
      if (
        starttime.isAfter(UtilityService.day()) &&
        res.data.data.find(a => {
          return UtilityService.day.utc(a.date_time).isSame(starttime);
        }) == undefined
      )
        times.push(starttime);
      starttime = UtilityService.day.utc(starttime).add(duration, "m");
    }

    return times;
  },
  groupTimes(times) {
    // console.log("times to group",times)

    let toReturn = [];
    if (times.length > 0) {
      let hour = times[0];
      let group = [];
      times.forEach(t => {
        // console.log("time", t, t.tz(UtilityService.getTimezone()).format('hh A').toString(), hour.tz(UtilityService.getTimezone()).format('hh A').toString())
        if (
          hour
            .tz(UtilityService.getTimezone())
            .format("hh A")
            .toString() ==
          t
            .tz(UtilityService.getTimezone())
            .format("hh A")
            .toString()
        ) {
          group.push(t.tz(UtilityService.getTimezone()));
        } else {
          toReturn.push({
            hour: hour
              .tz(UtilityService.getTimezone())
              .format("hh A")
              .toString(),
            times: group
          });
          group = [];
          hour = t;
          group.push(t.tz(UtilityService.getTimezone()));
        }
      });
      toReturn.push({
        hour: hour
          .tz(UtilityService.getTimezone())
          .format("hh A")
          .toString(),
        times: group
      });
      // console.log("grouped times", toReturn)
    }
    return toReturn;
  },
  getlog(id) {
    return UtilityService.getDS().record.getRecord(`appointment_log/${id}`);
  },
  async deletelog(id) {
    await UtilityService.getDS()
      .record.getRecord(`appointment_log/${id}`)
      .whenReady(record => {
        record.delete();
      });
  },
  async logApp(id, action, user) {
    let record = UtilityService.getDS()
      .record.getRecord(`appointment_log/${id}`)
      .get();
    console.log(id, record, action, user);

    let isEmpty = true;
    for (let keys in record) {
      console.log("keys", keys);
      isEmpty = false;
      break; // exiting since we found that the object is not empty
    }
    if (isEmpty) {
      console.log("record is empty", record);
      record = [
        {
          time: Date.now(),
          log: `${user.role.id === 4 ? "patient" : "doctor"} ${
            user.first_name
          } ${user.last_name} - ${action}`
        }
      ];
    } else {
      console.log("record is not empty empty", record);
      record.push({
        time: Date.now(),
        log: `${user.role.id === 4 ? "patient" : "doctor"} ${user.first_name} ${
          user.last_name
        } - ${action}`
      });
    }

    console.log(record);
    UtilityService.getDS()
      .record.getRecord(`appointment_log/${id}`)
      .set(record);
  },

  async getAllAppointments(page = this.data.page) {
    let result = await ApiService.get(
      "items",
      "appointments?fields=id,created_on,status,video_room_id,rating,type,date,time,date_time,medical_file," +
        "doctor.doctor_id.first_name,doctor.doctor_id.last_name,doctor.doctor_id.avatar.data,doctor.doctor_id.id," +
        "rating,medical_file.owner.id,medical_file.owner.first_name,medical_file.owner.last_name,medical_file.owner.avatar.data,medical_file.id,medical_file.birthday,medical_file.gender" +
        `&limit=${this.data.limit}&page=${page}&sort=-date_time&meta=*&filter[medical_file][neq]=null`
    );
    this.data.total = result.data.meta.total_count;
    return result;
  },
  async deleteItem(id) {
    return ApiService.delete(`items/appointments/${id}`);
  },
  async getSummery(id) {
    let idres = await ApiService.get(
      "items",
      "md_doctor_actions?fields=id&filter[appointment]=" + id
    );
    console.log("idres", idres);
    if (idres.data.data.length > 0) {
      return ApiService.get(
        "custom",
        "mf/getActionSummery/" + idres.data.data[0].id
      );
    } else return null;
  }
};
export default AppointmentService;
