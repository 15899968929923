import ApiService from "@/core/services/api.service";
//import axios from "axios";

/**
 * Service to call HTTP request via Axios
 */
const NotificationService = {
  init() {},
  async getDevicesByUser(id) {
    return ApiService.get(
      "items",
      `notification_center?fields=*,owner.*,owner.avatar.*&filter[owner]=${id}`
    );
  },
  async getDevicebyUUID(uuid, key) {
    return ApiService.get(
      "items",
      `notification_center?fields=id,owner,key&filter[status][neq]=deleted&filter[device_info][contains]=${uuid}&filter[key][logical]=or&filter[key][eq]=${key}`
    );
  },

  async syncToken(token, deviceInfo, currentuser) {
    this.getDevicebyUUID(deviceInfo.uuid, token).then(res => {
      // console.log("res from token search", res)
      if (res.data.data.length != 0) {
        // console.log("found token")
        if (
          res.data.data[0].owner !== currentuser.id ||
          token !== res.data.data[0].key
        ) {
          // console.log("updating token")
          let sendSub = {
            key: token,
            device_info: deviceInfo,
            owner: currentuser.id
          };
          ApiService.update(
            "items/notification_center",
            res.data.data[0].id,
            sendSub
          );
          this.subscribetoAll(token);
        }
      } else {
        // console.log("inserting token")
        let sendSub = {
          platform: deviceInfo.platform,
          key: token,
          device_info: deviceInfo,
          status: "published"
        };
        ApiService.post("items/notification_center", sendSub);
        this.subscribetoAll(token);
      }
    });
  },
  async subscribetoAll(token) {
    ApiService.post("custom/notification/subscribe", {
      token: token,
      topic: "broadcast"
    });
    ApiService.post("custom/notification/subscribe", {
      token: token,
      topic: "feed"
    });
  },
  async broadcastNotify(payload) {
    return ApiService.post("custom/notification/broadcast2", payload);
  },
  async feedNotify(payload) {
    return ApiService.post("custom/notification/feedupdate", payload);
  },
  async notifyByWebPush(incomingtitle, msg, recipient) {
    var payload = { messege: { title: incomingtitle, body: msg } };
    return ApiService.post(
      "custom/notification/notify2/" + recipient.id,
      payload
    );
  },
  async notifyByMail(incomingtitle, msg, recipient) {
    if (recipient.email != "") {
      var payload = {
        to: recipient.email,
        subject: incomingtitle,
        body: msg,
        type: "html",
        use_default_email: "true"
      };

      return ApiService.postWithToken("mail", payload);
    }
  },
  async sendOTP(incomingtitle, msg, recipient) {
    if (recipient.email != "") {
      var payload = {
        from: {
          email: "noreply@doctory.care",
          name: "Doctory Care Team"
        },
        to: [
          {
            email: recipient.email,
            name: msg.name
          }
        ],
        subject: incomingtitle,
        template_id: "jpzkmgqq3mg059v7",
        variables: [
          {
            email: recipient.email,
            substitutions: [
              {
                var: "company",
                value: "Doctory Care"
              },
              {
                var: "account.name",
                value: "Doctory Care"
              },
              {
                var: "support_email",
                value: "support@doctorycare.com"
              },
              {
                var: "variable",
                value: msg.code + ""
              }
            ]
          }
        ]
      };

      return ApiService.post("custom/notification/mail", payload);
    }
  },
  async notifyBySMS(incomingtitle, msg, recipient) {
    var payload = { messege: incomingtitle + "<br/>" + msg };
    return ApiService.post("custom/notification/sms/" + recipient.id, payload);
  }
};
export default NotificationService;
