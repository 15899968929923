import ApiService from "@/core/services/api.service";
/**
 * Service to call HTTP request via Axios
 */
const PrescriptionService = {
  data: {
    gun: null
  },

  setGun(g) {
    this.data.gun = g;
  },

  async createMedicine(payload) {
    return ApiService.post("items/medicine", payload);
  },

  async getUserPrescriptions(id) {
    let res = await ApiService.get(
      `items/md_prescription?fields=owner.*,owner.avatar.*,*,medication.*,medication.medicine.*,medication.time.prescription_intake_time_id.*&filter[medical_file]=${id}&sort=-created_on`
    );
    let toreturn = res.data.data.map(p => {
      if (p.owner == null) {
        p.owner = { first_name: "Doctory", last_name: "Doctor" };
      }
      p.medication = p.medication.map(m => {
        m.time = m.time.map(t => {
          return t.prescription_intake_time_id.time;
        });
        return m;
      });

      return p;
    });
    return toreturn;
  }
};
export default PrescriptionService;
