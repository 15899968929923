<template>
  <div class="hidescroll">
    <transition name="fade">
      <div
        v-show="$root.loader"
        style="
          position: absolute;
          background-color: white;
          width: 100vw;
          height: 100vh;
          z-index: 10000000;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <lottie-animation
          :animationData="
            require('@/assets/animation/lf30_editor_jzc8pwn6.json')
          "
          :loop="true"
          :autoPlay="true"
          style="height: 100px !important"
        />

        <b-progress
          :value="progress"
          height="7px"
          :style="$root.mobile ? 'width:50vw' : 'width:15vw'"
        ></b-progress>
      </div>
    </transition>
    <div v-if="isReady" style="">
      <router-view
        :dir="pointto"
        :direction="pointto"
        :class="pointto == 'rtl' ? 'rtl' : ''"
      ></router-view>
    </div>
    <div
      class="customfullview"
      style="
        background-color: rgba(255, 255, 255, 0.9);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      "
      v-if="!$root.connectionstatus"
    >
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <lottie-animation
          style="height: 150px"
          :animationData="require('@/assets/animation/signal.json')"
          :autoPlay="true"
          :loop="true"
        />
        <div style="margin-top: -30px; font-size: 1.2rem">
          Couldn't Connect to the Internet
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
// @import "assets/css/style.vue.rtl";
</style>

<style lang="scss">
@import "assets/css/style.vue";
@import "assets/css/custom";
</style>

<script>
//import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import LottieAnimation from "lottie-web-vue";
import { Plugins } from "@capacitor/core";
import jwtService from "./core/services/jwt.service";

//const rtl = () => import("./assets/css/style.vue.rtl.css");
//const ltr = () => import("./assets/css/style.vue.css");

//ltr();
/*import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";*/

export default {
  name: "doctory-care",
  components: {
    LottieAnimation
  },
  data() {
    return { pointto: "ltr", isReady: false, progress: 0 };
  },
  watch: {
    progress: function(update) {
      if (update == 100) {
        setTimeout(() => {
          this.$root.loader = false;
          this.isReady = true;
        }, 1500);
      }
    }
  },
  methods: {
    async setup() {
      const { Device } = Plugins;
      let info = await Device.getInfo();
      this.$util.setDeviceInfo(info);

      // // console.log("setting up lang in setup")
      // // console.log("before creae and lang (localstorage) is", localStorage.language, "root value",this.$root.lang)
      if (localStorage.language == "ar") {
        //rtl();
        //this.$forceUpdate();
        this.pointto = "rtl";
        this.$vuetify.rtl = true;
      } else {
        //ltr();
        //this.$forceUpdate();
        this.pointto = "ltr";
        this.$vuetify.rtl = false;
      }

      // // console.log("in app setup");
      if (jwtService.getToken() != null) {
        await this.$user.fetchCurrentUser().then(async () => {
          let user = this.$user.getCurrentUser();

          // // console.log("node env is", process.env.NODE_ENV);
          if (process.env.NODE_ENV === "production") {
            // this.$user.logAccess(this.$util.getDeviceInfo());
            this.progress += 10;
            this.$rocket.identify(user.external_id, {
              name: user.first_name + " " + user.last_name,
              email: user.email
            });
          } else this.progress += 10;

          this.progress += 10;
          this.$util.startUpDS(user);
          if (user.role.id == 4) {
            //loading mf
            await this.$file.getItemByID(user.id, true).then(async data => {
              // console.log("mdeical file is", data, user);
              if (data === undefined) {
                await this.$file.createItem(user).then(data => {
                  // console.log("new mf is", data.data.data);
                  this.$root.mf = data.data.data.id;
                  this.$file.data.currentFile = data.data.data;
                  this.progress += 10;
                });
              } else {
                this.$root.mf = data.id;
                this.progress += 10;
              }
            });
          } else if (user.role.id == 3) {
            await this.$doctor.getItemByID(user.id, true).then(data => {
              //// console.log("doctor is", data);
              this.$root.docID = data.id;
              this.progress += 10;
            });
          } else {
            this.progress += 10;
          }
        });
      } else {
        this.progress += 30;
      }

      this.setGeoData();
      this.progress += 10;
    },
    finishSetup() {
      if (!localStorage.SETTINGS) {
        this.$util.getSettings();
        this.progress += 10;
      } else {
        this.progress += 10;
      }
      this.$util.getLanguages();
      this.progress += 10;

      this.$department.getPouch();
      this.progress += 10;
      this.$doctor.init();
      this.progress += 10;

      this.$vitalTypes.init();
      this.$historyTypes.init();
      this.$payments.init();
    },
    async fetchSettings() {
      await ApiService.get("items", "settings/1").then(
        ({ data }) => {
          //// console.log("settings", data);
          //// console.log('call duration is ', data.data['call_duration']);
          window.localStorage.setItem("SETTINGS", JSON.stringify(data.data));
        },
        error => {
          console.error(error);
        }
      );
    },
    setGeoData() {
      if (!window.localStorage.geoData) {
        ApiService.getLocationDetails().then(data => {
          //// console.log("result of location fencing", data);
          window.localStorage.setItem("geoData", JSON.stringify(data.data));
          /*// console.log(
            "*************** timezone moment is: ",
            this.$moment.tz.guess()
          );*/
          // console.log("*************** timezone geo is: ", data.data.timezone);
          this.progress += 20;
        });
      } else {
        let data = JSON.parse(localStorage.geoData);
        /* // console.log(
          "*************** timezone moment is: ",
          this.$moment.tz.guess()
        );*/
        // console.log("*************** timezone geo is: ", data.timezone);
        localStorage.setItem("timezone", data.timezone);
        this.progress += 20;
      }
    },
    couchready() {}
  },
  computed: {},
  async beforeCreate() {
    //setTimeout(() => (), 1500);
    // // console.log("before creae and lang (localstorage) is", localStorage.language, "root value",this.$root.lang)
    if (localStorage.language == "ar") {
      //rtl();
      //this.$forceUpdate();
      this.pointto = "rtl";
      this.$vuetify.rtl = true;
    } else {
      //ltr();
      //this.$forceUpdate();
      this.pointto = "ltr";
      this.$vuetify.rtl = false;
    }
  },
  created() {},
  beforeMount() {
    const { Network } = Plugins;
    Network.getStatus().then(data => {
      // console.log("network status", data);
      this.$root.connectionstatus = data.connected;
    });
    Network.addListener("networkStatusChange", status => {
      this.$root.connectionstatus = status.connected;
      // console.log("Network status changed", status);
    });
  },
  mounted() {
    if (!this.$root.didload) this.setup();

    this.$util.EventBus.$on("couchready", () => {
      // console.log("ready to finish setup");
      this.finishSetup();
    });
    this.$util.EventBus.$on("langChanged", data => {
      // console.log("lang change event",data)
      this.$root.lang = data;
      this.$moment.locale(data);
      if (data == "ar") {
        //rtl();
        //this.$forceUpdate();
        this.pointto = "rtl";
        this.$vuetify.rtl = true;
      } else {
        //ltr();
        //this.$forceUpdate();
        this.pointto = "ltr";
        this.$vuetify.rtl = false;
      }
      this.$forceUpdate();
    });
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    //this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);

    this.$nextTick(function() {
      //// console.log("language is ", localStorage.language);
      if (screen.width <= 815) {
        this.$root.mobile = true;
      } else {
        this.$root.mobile = false;
      }
      //this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      if (localStorage.language) {
        this.$root.lang = localStorage.language;
      }
      if (localStorage.language == "ar") {
        //require('./assets/css/style.vue.rtl.css');
        this.pointto = "rtl";
        this.$vuetify.rtl = true;
        //this.$forceUpdate();
      } else {
        //require('./assets/css/style.vue.css');
        this.pointto = "ltr";
        this.$vuetify.rtl = false;
        //this.$forceUpdate();
      }
      try {
        if (localStorage.language != "en")
          require("moment/locale/" + localStorage.language);
      } catch (e) {
        if (e) {
          // console.log("couldn't find english");
        }
      }
      /*setTimeout(() => {
        // Remove page loader after some time
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      }, 2000);*/

      //this.$root.currentpage =
    });
  }
};
</script>
<style>
.v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
  opacity: 0;
}
.theme--light.v-list-item:hover::before {
  opacity: 0;
}
</style>
