import ApiService from "@/core/services/api.service";
import ShortUniqueId from "short-unique-id";
import Vue from "vue";
import VueCryptojs from "vue-cryptojs";
import { DeepstreamClient } from "@deepstream/client";
import * as dayjs from "dayjs";
import DbService from "./db.service";

/**
 * User Management Service
 * - get users
 * - add users
 * - edit users
 * - delete users
 * - store current user
 */
const UtilityService = {
  init(ds, us) {
    this.getSettings();
    this.data.ds = ds;
    this.data.user = us;
    Vue.use(VueCryptojs);

    var utc = require("dayjs/plugin/utc");
    var timezone = require("dayjs/plugin/timezone");
    var duration = require("dayjs/plugin/duration");
    var advancedFormat = require("dayjs/plugin/advancedFormat");
    var localeData = require("dayjs/plugin/localeData");

    dayjs.extend(localeData);
    dayjs.extend(advancedFormat);
    dayjs.extend(duration);
    dayjs.extend(utc);
    dayjs.extend(timezone);
  },
  EventBus: new Vue(),
  day: dayjs,
  asseturl: process.env.VUE_APP_api_url + "assets/",
  data: {
    gun: null,
    langs: null,
    settings: null,
    deviceinfo: null,
    ds: null,
    emitter: null,
    user: null,
    fx: 1
  },
  setGun(g) {
    this.data.gun = g;
  },
  newDS() {
    this.data.ds = new DeepstreamClient(process.env.VUE_APP_ds);
  },
  getDS() {
    return this.data.ds;
  },
  truncate(str, n) {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  },
  getUniq(array, field) {
    let toreturn = [];
    array.forEach(obj => {
      if (
        toreturn.findIndex(i => {
          return i[field] === obj[field];
        }) === -1
      )
        toreturn.push(obj);
    });
    return toreturn;
  },
  startUpDS(user) {
    // // console.log("starting up ds with user",user)
    this.data.ds.login({ username: user.external_id }, async () => {
      // // console.log("logged into ds")
      this.openUserSocket(user.external_id);
    });
    this.data.ds.presence.subscribe((user, login) => {
      this.EventBus.$emit("onlineStatusUpdate", {
        external_id: user,
        status: login
      });
    });

    this.data.emitter = this.data.ds.event;
  },
  closeDS() {
    this.data.ds.close();
  },
  openUserSocket(name) {
    this.data.emitter.subscribe(name, value => {
      // console.log(`got ${value} from channel ${name}`)
      const decoded = JSON.parse(value);
      if (decoded.addChatRoom) {
        this.EventBus.$emit("addNewRemoteChat", decoded.addChatRoom);
      }
    });

    this.data.emitter.subscribe("doctory_updates", value => {
      if (!process.env.NODE_ENV === "production") {
        console.log("got updates from server and they are", value);
      }
      if (value.type === "appointment_update") {
        let tempid = this.data.user.getCurrentUser().id;
        if (
          tempid === value.data.data.owner.id ||
          tempid === value.data.data.doctor.doctor_id.id
        ) {
          this.EventBus.$emit("updateAppointmentstatus", value.data);
          this.EventBus.$emit("showalert", {
            msg: "You have an appointment in 5 mintues"
          });
        }
      }
    });
  },
  getEmitter() {
    return this.data.emitter;
  },
  encrypt(payload, key) {
    return this.EventBus.CryptoJS.AES.encrypt(
      JSON.stringify(payload),
      key
    ).toString();
  },
  decrypt(payload, key) {
    /*// console.log("dycrpeted", this.EventBus.CryptoJS.AES.decrypt(payload,key).toString(
      this.EventBus.CryptoJS.enc.Utf8
    ))*/
    return JSON.parse(
      this.EventBus.CryptoJS.AES.decrypt(payload, key).toString(
        this.EventBus.CryptoJS.enc.Utf8
      )
    );
  },
  generateUID() {
    return new ShortUniqueId().stamp(32);
  },
  setDeviceInfo(info) {
    this.data.deviceinfo = info;
  },
  getDeviceInfo() {
    return this.data.deviceinfo;
  },
  getSettings(force = false) {
    if (this.data.settings != null && !force) return this.data.settings;
    DbService.getlocaldb()
      .get("settings")
      .then(data => {
        window.localStorage.setItem("SETTINGS", JSON.stringify(data));
        this.data.settings = data;
        return this.data.settings;
      });
  },
  getTranslation(d, lang = localStorage.language) {
    if (d.translation && d.translation.length > 0) {
      for (var i = 0; i < d.translation.length; i++) {
        if (d.translation[i].lang == lang) {
          return d.translation[i];
        }
      }
    } else {
      return d;
    }
  },
  async getExchangeRate() {
    const res = await ApiService.convertCurrency(
      "GBP",
      JSON.parse(localStorage.geoData).currencyCode
    );

    const rate = parseFloat(
      res.data.rates[JSON.parse(localStorage.geoData).currencyCode].rate
    ).toFixed(2);
    this.data.fx = rate;
    return this.data.fx;
  },
  getLocalRate() {
    return this.data.fx;
  },
  getLocalCurrency() {
    return JSON.parse(localStorage.geoData).currencySymbolNative;
  },
  isPaymentEnabled() {
    return this.data.settings.payment;
  },
  getLanguages(force = false) {
    if (this.data.langs == null || force) {
      this.data.langs = [];
      DbService.getlocaldb()
        .get("languages")
        .then(data => {
          this.data.langs = data;
          // console.log("langs pouch",this.data.langs)
          return this.data.langs.data;
        });
    } else {
      // console.log("langs local",this.data.langs)
      return this.data.langs.data;
    }
  },
  getLanguageByID(id) {
    return this.data.langs.data.find(l => {
      return l.id === id;
    });
  },
  setLanguages() {
    ApiService.get(
      "items/languages?fields=id,name,code,flag.private_hash"
    ).then(data => {
      let lang = {};
      lang = data.data.data.map(lang => {
        return {
          id: lang.id,
          name: lang.name,
          code: lang.code,
          icon: lang.flag.private_hash
        };
      });
      // console.log("flat langs", lang);
      DbService.getlocaldb()
        .put({ _id: "languages", _rev: this.data.langs["_rev"], data: lang })
        .then(data => {
          // console.log("did put languages in pouch",data)
          this.data.langs["_rev"] = data.rev;
        });
    });
  },
  getTimezone() {
    return JSON.parse(localStorage.geoData).timezone;
  },
  langFilter(lang, queryText) {
    return lang.name.toLowerCase().includes(queryText.toLowerCase());
  },
  zoneFilter(z, queryText) {
    return z.zone.toLowerCase().includes(queryText.toLowerCase());
  },
  departmentFilter(department, queryText) {
    return department.name.toLowerCase().includes(queryText.toLowerCase());
  },
  doctorFilter(doctor, queryText) {
    return `${doctor.doctor_id.first_name} ${doctor.doctor_id.last_name}`
      .toLowerCase()
      .includes(queryText.toLowerCase());
  },
  processTime(date) {
    return date.includes("T") ? date : date + "Z";
  },

  merge(array1, array2) {
    console.log("array 1 (new data)", array1.length, "array2", array2.length);
    if (array1 === undefined || array1.length == 0) {
      console.log("******merge to return 1");
      return array2;
    } else if (array2 === undefined || array2.length == 0) {
      console.log("******merge to return 2");
      return array1;
    }
    let ids = new Set(array2.map(d => d.id));
    console.log("ids", ids);
    let merged = [...array2, ...array1.filter(d => !ids.has(d.id))];

    console.log("-------- ", merged);

    return merged;
  }
};
export default UtilityService;
