import ApiService from "@/core/services/api.service";
import DbService from "@/core/services/db.service";
/**
 * Service to call HTTP request via Axios
 */
const DepartmentService = {
  data: {
    departments: null,
    gun: null
  },
  init() {
    this.getDepartments();
  },
  getPouch() {
    DbService.getlocaldb()
      .get("departments")
      .then(data => {
        this.data.departments = data;
        return data.data;
      });
  },
  updatePouch(d) {
    let payload = {};
    payload["_id"] = "departments";
    payload["_rev"] = this.data.departments["_rev"];
    payload["data"] = d;
    // console.log("adding this to pouch",payload)
    DbService.getlocaldb()
      .put(payload)
      .then(data => {
        // console.log("added departmetns to pouch",data)
        this.data.departments["_rev"] = data.rev;
      });
  },

  async create(payload) {
    let res = ApiService.post("items/department", payload);
    return res;
  },
  async update(id, payload) {
    let res = ApiService.update("items/department", id, payload);
    return res;
  },
  async deleteItem(id) {
    let res = ApiService.delete(`items/department/${id}`);
    return res;
  },
  async deleteTranslation(payload) {
    let ids = "";
    payload.forEach((item, index) => {
      ids += item.id;
      if (index != payload.length - 1) ids += ",";
    });
    if (ids != "") {
      let res = ApiService.delete(`items/department_details/${ids}`);
      // console.log(res);
      return res;
    }
    return false;
  },
  /**
   * GET All departments
   * @param force if true, force loading from server, if false, return local departments if available, if not, load from server
   * @returns {departments}
   */
  async getDepartments(force = false) {
    let fetchfromserver = this.data.departments == null || force;
    //// console.log("data",this.data, "is data null", this.data.departments == null, "fetch from server",fetchfromserver);
    if (fetchfromserver) {
      // console.log("loading from remote")
      let result = await ApiService.get(
        "items",
        "department?fields=id,name,avatar.filename_download,avatar.private_hash,avatar.data,translation.*,doctors.doctor_id.status,doctors.doctor_id.external_id"
      );
      if (result.data) {
        result.data.data.forEach(depart => {
          depart.doctors = depart.doctors.filter(doc => {
            return doc.doctor_id.status !== "suspended";
          });
        });
      }
      this.data.departments.data = result.data.data;

      this.updatePouch(result.data.data);
      return this.data.departments.data;
    } else {
      // console.log("returning from local", this.data.departments)
      return this.data.departments.data;
    }
  }
};
export default DepartmentService;
