import PouchDB from "pouchdb";
import UtilityService from "./utility.service";
import DepartmentService from "./department.service";
import HistoryService from "./mf/history.service";
import VitalService from "./mf/vital.service";
import PaymentService from "./payment.service";
import DoctorService from "./doctor.service";

/**
 * User Management Service
 * - get users
 * - add users
 * - edit users
 * - delete users
 * - store current user
 */
const DbService = {
  init() {
    let url = `https://${process.env.VUE_APP_COUCH_CRD}@${process.env.VUE_APP_COUCH_URL}/doctory`;
    let opts = { live: true, retry: true };
    this.data.local = new PouchDB("doctory");
    // do one way, one-off sync from the server until completion
    this.data.local.replicate.from(url).on("complete", () => {
      UtilityService.EventBus.$emit("couchready");
      // console.log("replicated remote and now starting to sync")
      this.data.local.sync(url, opts).on("change", data => {
        // console.log("sync change event", data)

        let settings = data.change.docs.find(doc => {
          return doc["_id"] === "settings";
        });

        if (settings) {
          UtilityService.getSettings(true);
        }
        let depart = data.change.docs.find(doc => {
          return doc["_id"] === "departments";
        });
        if (depart) {
          DepartmentService.getPouch();
        }
        let docs = data.change.docs.find(doc => {
          return doc["_id"] === "doctors";
        });
        if (docs) {
          DoctorService.getPouch();
        }
        let vitals = data.change.docs.find(doc => {
          return doc["_id"] === "vital_types";
        });
        if (vitals) {
          VitalService.getPouch();
        }
        let history = data.change.docs.find(doc => {
          return doc["_id"] === "history_type";
        });
        if (history) {
          HistoryService.getPouch();
        }
        let payment = data.change.docs.find(doc => {
          return doc["_id"] === "payment_methods";
        });
        if (payment) {
          PaymentService.getPouch();
        }
        let langs = data.change.docs.find(doc => {
          return doc["_id"] === "languages";
        });
        if (langs) {
          UtilityService.getLanguages(true);
        }
      });
    });

    /*this.data.local.info().then((info) => {
      // console.log("local db info", info);
    })*/
  },

  data: {
    local: null,
    remote: null
  },

  getlocaldb() {
    return this.data.local;
  },
  getremotedb() {
    return this.data.remote;
  }
};
export default DbService;
