import ApiService from "@/core/services/api.service";

/**
 * User Management Service
 * - get users
 * - add users
 * - edit users
 * - delete users
 * - store current user
 */
const UserService = {
  init() {},
  data: {
    current_user: null,
    users: null,
    page: 0,
    roles: null,
    gun: null
  },

  setCurrentUser(user) {
    this.data.current_user = user;
  },
  async fetchCurrentUser() {
    // // console.log("getting current user");
    ApiService.setHeader();
    return await ApiService.get("users/me?fields=*,avatar.private_hash")
      .then(data => {
        // // console.log("get remote current user", data);
        this.setCurrentUser(data.data.data);
      })
      .catch(error => {
        if (error.response.status == 401) {
          // console.log("auth error", error.response);
          this.onLogout();
          throw new Error("logging out error");
        }
      });
  },
  getCurrentUser() {
    return this.data.current_user;
  },
  clearData() {
    this.data.current_user = null;
    this.data.users = null;
    this.data.page = 0;
    this.data.roles = null;
  },

  onLogout() {
    // console.log("logging out");

    this.clearData();
  },

  async logAccess(device) {
    // console.log("device to log is",device)
    const payload = {
      device_info: device
    };
    return ApiService.post("items/accesslogger", payload);
  },

  async getLastAccess(id) {
    let result = await ApiService.get(
      `/items/accesslogger?fields=*.*&filter[owner]=${id}&sort=-created_on&limit=1`
    );
    if (result.data.data) {
      // // console.log("last accessed on,", result.data.data[0])
      return result.data.data[0];
    } else return null;
  },

  /**
   * GET All Roles
   * @param force if true, force loading from server, if false, return local departments if available, if not, load from server
   * @returns {roles}
   */
  async getRoles(force = false) {
    let fetchfromserver = this.data.roles == null || force;
    if (fetchfromserver) {
      // console.log("loading from remote");
      let result = await ApiService.get("/roles?fields=*.*&filter[id][neq]=2");
      this.data.roles = result.data.data;
      return this.data.roles;
    } else {
      // console.log("returning from local", this.data.roles);
      return this.data.roles;
    }
  },

  async getUsersByRole(role, force = false) {
    let fetchfromserver = this.data.users == null || force;
    if (fetchfromserver) {
      // console.log("loading from remote");
      const { data } = await ApiService.get(
        "/roles/" +
          role +
          "?fields=*.*,users.*,users.avatar.*,users.external_id"
      );
      this.data.users = data.data;
      return this.data.users;
    } else {
      // console.log("returning from local", this.data.users);
      return this.data.users;
    }
  },

  async getElegiableChatUsers() {
    let doctors = await ApiService.get(
      "/items/doctor?fields=department.*.*,doctor_id.*,doctor_id.avatar.private_hash"
    );
    let users = doctors.data.data.map(d => {
      d.doctor_id.department = d.department;
      return d.doctor_id;
    });
    const { data } = await ApiService.get(
      "/users?fields=*,role.*,avatar.id,avatar.private_hash&filter[role][in]=1,5,6"
    );

    return users.concat(data.data).sort((a, b) => {
      if (a.first_name > b.first_name) return 1;
      else if (a.first_name < b.first_name) return -1;
      else return 0;
    });
  },

  /**
   *
   * @param {*} id
   * @param {*} key
   * @param {*} value
   * @param {*} resource
   * @returns
   */
  async updateUserInfo(id, key, value, resource) {
    var payload = {};
    payload[key] = value;
    return ApiService.update(resource, id, payload);
  },
  async updateUser(id, payload) {
    return ApiService.update("users", id, payload);
  },
  async createUser(payload) {
    return ApiService.post("custom/register/register_user", payload);
  },
  async checkEmail(email) {
    return ApiService.get("users?fields=id&filter[email]=" + email, "", true);
  },
  async createUser2(payload) {
    return ApiService.post("users", payload);
  },
  async forgotPasswordSendOTP(payload) {
    return ApiService.post("custom/notification/mailOTP", payload);
  },
  async verifyOTP(payload) {
    return ApiService.post("custom/notification/verifyOTP", payload);
  },
  async resetpass(payload) {
    return ApiService.post("custom/register/resetpass", payload);
  }
};
export default UserService;
