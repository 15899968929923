import ApiService from "@/core/services/api.service";
import UtilityService from "@/core/services/utility.service";
import UserService from "./user.service";
/**
 * User Management Service
 * - get users
 * - add users
 * - edit users
 * - delete users
 * - store current user
 */
const ChatService = {
  init(g) {
    UtilityService.EventBus.$on("addNewRemoteChat", data => {
      // console.log("recived update",data, "rooms are", this.data.rooms)
      this.AddRemoteRoom(data);
    });
    this.data.gun = g;
  },
  data: {
    gun: null,
    rooms: [],
    room: null
  },
  setGun(g) {
    this.data.gun = g;
  },

  getActiveRoom() {
    return this.data.room;
  },
  setActiveRoom(r) {
    this.data.room = r;
    UtilityService.EventBus.$emit("chatRoomChange");
  },
  clearData() {
    this.data.room = null;
    this.rooms = [];
  },
  async getUserChats(id, force = false) {
    if (this.data.rooms.length == 0 || force) {
      let rooms = null;
      const data = await ApiService.get(
        `items/user_chat?fields=*,conv_id.*,conv_id.users.user_id.*,conv_id.users.user_id.avatar.private_hash&filter[user_id]=${id}`
      );

      rooms = data.data.data.map(r => {
        // console.log("getting room")
        const users = r.conv_id.users.map(u => {
          return u.user_id;
        });
        let remote = null;
        if (users.length == 2) {
          remote = users.find(u => {
            return u.id !== id;
          });
        }
        return {
          created_on: r.conv_id.created_on,
          id: r.conv_id.id,
          key: r.conv_id.key,
          title: r.conv_id.title,
          users: users,
          remote: remote
        };
      });
      if (this.data.rooms.length > 0) {
        const filteredrooms = rooms.filter(r => {
          return (
            this.data.rooms.find(d => {
              return d.key == r.key;
            }) === undefined
          );
        });
        this.data.rooms = filteredrooms;
      } else {
        this.data.rooms = rooms;
      }

      this.data.rooms.forEach((r, index) => {
        this.data.gun.get(`chat/${r.key}`).once(data => {
          if (data) {
            let chat = [];
            Object.keys(data).forEach(ro => {
              if (ro != "_") chat.push(UtilityService.decrypt(data[ro], r.key));
            });
            r.chat = chat.sort((a, b) => {
              if (a.timestamp > b.timestamp) return 1;
              else if (a.timestamp < b.timestamp) return -1;
              else return 0;
            });
            UtilityService.EventBus.$emit("setChatHistoryToRoom", {
              index: index,
              chat: chat
            });
          }
        });
      });

      return this.data.rooms;
    } else return this.data.rooms;
  },
  generateRoomObj(id, users, key = "", title = "") {
    let remote = null;
    if (users.length == 2) {
      remote = users.find(u => {
        return u.id !== id;
      });
    }
    return {
      key: key,
      title: title,
      users: users,
      remote: remote,
      chat: []
    };
  },
  AddRemoteRoom(e) {
    const found =
      this.data.rooms.length > 0
        ? this.data.rooms.find(r => {
            return r.key == e.key;
          })
        : undefined;
    if (found == undefined) {
      if (e.remote) {
        e.remote = e.users.find(u => {
          return u.id !== UserService.getCurrentUser().id;
        });
      }
      this.data.rooms.push(e);
    }
  },
  async saveRoom(users, key = "", title = "") {
    const userstosend = users.map(user => {
      return { user_id: { id: user.id } };
    });
    const payload = {
      key: key,
      title: title,
      users: userstosend
    };
    // console.log("users to send",payload);
    return ApiService.post("items/conversations", payload);
  },

  encryptMsg(msg) {
    return UtilityService.encrypt(msg, this.data.room.key);
  }
};
export default ChatService;
