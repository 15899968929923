import ApiService from "@/core/services/api.service";
import UtilityService from "@/core/services/utility.service";
import { Base64 } from "js-base64";
import DbService from "./db.service";

/**
 * Service to call HTTP request via Axios
 */
const DoctorService = {
  init() {
    this.getPouch();
  },
  data: {
    currentDoctor: null,
    allDoctors: null,
    errnings: null
  },
  async getPouch() {
    let res = await DbService.getlocaldb().get("doctors");
    this.data.allDoctors = res;
  },
  updatePouch() {
    DbService.getlocaldb()
      .put(this.data.allDoctors)
      .then(data => {
        this.data.allDoctors["_rev"] = data.rev;
      });
  },
  updatedPouchwithArray(items) {
    this.data.allDoctors.data = Base64.encode(JSON.stringify(items));
    this.updatePouch();
  },
  setPouch() {
    // console.log("setting history pouch")
    DbService.getlocaldb().put({
      _id: "doctors",
      data: Base64.encode(JSON.stringify(this.data.allDoctors))
    });
  },
  clearData() {
    this.data.currentDoctor = null;
    this.data.errnings = null;
  },
  async updateLocalPrice(docs, force = false) {
    if (force) await UtilityService.getExchangeRate();
    docs.forEach(doctor => {
      doctor.local_video = (
        doctor.video * UtilityService.getLocalRate()
      ).toFixed(2);
      doctor.local_clinic = (
        doctor.clinic * UtilityService.getLocalRate()
      ).toFixed(2);
      parseInt(doctor.video) === 0 && parseInt(doctor.clinic) === 0
        ? (doctor.isFree = true)
        : (doctor.isFree = false);
    });
  },
  getLocalDocs() {
    return JSON.parse(Base64.decode(this.data.allDoctors.data));
  },
  async getAllDoctors(force = false) {
    if (force) {
      let docs = JSON.parse(Base64.decode(this.data.allDoctors.data));
      console.log("new get all docs", docs);
      await this.updateLocalPrice(docs, true);
      return docs;
    } else return JSON.parse(Base64.decode(this.data.allDoctors.data));
  },
  async getAllDoctorsBackend(force = false) {
    let fetchfromserver = this.data.allDoctors == null || force;
    //// console.log("data",this.data, "is data null", this.data.departments == null, "fetch from server",fetchfromserver);
    if (fetchfromserver) {
      let result = await ApiService.get(
        "items",
        "doctor?fields=*,doctor_id.id,doctor_id.external_id,doctor_id.first_name,doctor_id.last_name," +
          "doctor_id.status,doctor_id.avatar.private_hash,languages_spoken.languages_id.*.*,availability.*,days_off.*"
      );
      console.log("results", result);
      let res = [];
      if (result.data) {
        res = result.data.data.filter(doc => {
          return doc.doctor_id != null && doc.doctor_id.status !== "suspended";
        });
      }
      this.data.allDoctors.data = Base64.encode(JSON.stringify(res));
      this.updatePouch();
      this.updateLocalPrice(res);
      console.log("doctors list after filtering", res);
      return res;
    } else {
      return this.data.allDoctors;
    }
  },
  getDoctorsByDepartment(id) {
    return this.data.allDoctors.filter(doc => {
      return doc.department.id == id;
    });
  },
  /**
   * GET Doctory By ID
   * @param force if true, force loading from server, if false, return local items if available, if not, load from server
   * @returns {doctor}
   */
  async getItemByID(id, force = false) {
    let fetchfromserver = this.data.currentDoctor == null || force;
    if (fetchfromserver) {
      let result = await ApiService.get(
        "items",
        `doctor?fields=*,doctor_id.id,doctor_id.external_id,doctor_id.first_name,doctor_id.last_name,doctor_id.status,doctor_id.avatar.private_hash,languages_spoken.languages_id.*.*,availability.*,days_off.*&filter[doctor_id]=${id}`
      );
      this.data.currentDoctor = result.data.data[0];
      return this.data.currentDoctor;
    } else {
      return this.data.currentDoctor;
    }
  },
  updateCurrentDoctor(d) {
    this.data.currentDoctor = d;
  },
  getCurrentDoctor() {
    return this.data.currentDoctor;
  },
  async getProfileInfo(doc) {
    let rating = await ApiService.get(
      "items",
      `feedback?fields=rating,comment,id&filter[doctor]=${doc.id}&filter[status]=published`
    );
    let answers = await ApiService.get(
      "items",
      `question?fields=id&filter[doctor]=${doc.id}`
    );
    let appointments = await ApiService.get(
      "items",
      `appointments?fields=id&filter[doctor]=${doc.id}`
    );
    let posts = await ApiService.get(
      "items",
      `feed?fields=id&filter[owner]=${doc.doctor_id.id}`
    );
    return {
      rating: rating.data.data,
      answers: answers.data.data,
      apps: appointments.data.data,
      posts: posts.data.data
    };
  },
  async getErrningsByID(id, force = false) {
    let fetchfromserver = this.data.errnings == null || force;
    if (fetchfromserver) {
      let result = await ApiService.get(
        "items",
        `appointments?fields=*.id,owner.first_name,owner.last_name,owner.avatar.*,payment.*,payment.method.*&filter[doctor]=${id}&filter[status][neq]=deleted&sort=-created_on`
      );
      this.data.errnings = result.data.data;
      return this.data.errnings;
    } else {
      return this.data.errnings;
    }
  },
  getDoctorCallDuration(doctor = null) {
    let tocheck = doctor == null ? this.data.currentDoctor : doctor;
    if (tocheck.call_duration) return tocheck.call_duration;
    else return UtilityService.getSettings().call_duration;
  },
  updateDoctorCache(id, stat = "active") {
    UtilityService.getDS().rpc.make("updateDoctorsList", {
      id: id,
      status: stat
    });
  },
  async createDoctor(payload) {
    return ApiService.post("custom/register/register_doctor", payload);
  },
  async updateDoctor(id, payload) {
    return ApiService.update("items/doctor", id, payload);
  },
  async addScheduleShift(payload) {
    return ApiService.post("items/doctor_availability", payload);
  },
  async deleteScheduleShift(id) {
    return ApiService.delete(`items/doctor_availability/${id}`);
  },
  async addDayOff(payload) {
    return ApiService.post("items/doctor_exception", payload);
  },
  async deleteDayOff(id) {
    return ApiService.delete(`items/doctor_exception/${id}`);
  },
  async updateSpokenLanguages(id, langs) {
    let result = await ApiService.get(
      "items",
      `doctor_languages?fields=*&filter[doctor_id]=${id}`
    );
    let ids = result.data.data.map(i => {
      return i.id;
    });
    if (ids.length > 0)
      await ApiService.delete(`items/doctor_languages/${ids.join()}`);

    const payload = langs.map(i => {
      return { doctor_id: id, languages_id: i };
    });

    return ApiService.post("items/doctor_languages", payload);
  }
};
export default DoctorService;
